<template>
  <div style="background-color: #f6f6f6" v-if="detail.name">
    <van-nav-bar
      :title="detail.name ? detail.name : '课程详情'"
      :left-arrow="!share"
      @click-left="$goBack()"
      fixed
      placeholder
      @click-right="shareBoxShow = true"
    >
      <template #right>
        <img
          v-if="!share"
          src="../../assets/img/takeOutFood/share.png"
          alt=""
          style="width: 24px; height: 24px"
        />
      </template>
    </van-nav-bar>

    <div>
      <img :src="detail.img" alt="" style="width: 100%" />
    </div>

    <div class="title-box">
      <div>
        <div class="title">{{ detail.name }}</div>
        <div class="price">
          <span>￥</span>
          <span>{{ detail.price }}</span>
        </div>
      </div>
      <div class="num">
        <span>{{ detail.peopleNum || 0 }}</span>
        <span>人报名</span>
      </div>
    </div>

    <div>
      <van-tabs
        v-model="active"
        line-width="10"
        color="#ff5200"
        title-active-color="#ff5200"
        title-inactive-color="#999"
        swipeable
      >
        <van-tab title="课程介绍">
          <div class="desc" v-html="detail.introduction"></div>
        </van-tab>

        <van-tab title="名师简介">
          <div class="teacher">
            <div
              class="teacher-item"
              v-for="(item, index) in detail.pekingTeachersList"
              :key="index"
            >
              <div class="item-left">
                <img :src="item.img" alt="" />
              </div>
              <div class="item-right">
                <div style="margin-bottom: 5px">
                  <span class="name">{{ item.name }}</span>
                  &nbsp;
                  <span class="explain">(仅供参考，具体以实际授课为准)</span>
                </div>
                <div class="explain">{{ item.introduction }}</div>
              </div>
            </div>
          </div>
        </van-tab>

        <van-tab title="课程目录">
          <div class="catalog">
            <div class="catalog-item" v-for="(item, index) in detail.pekingCatalogueList" :key="index">
              <!-- <div > -->
                <div class="first">第{{convertToChinaNum(index+1)}}章&nbsp;&nbsp;{{ item.content }}</div>
                <!-- <div>
                  <a :href="detail.status?item.url:'javascript:;'" class="notHave"  :class="{have:detail.status}">学习课程</a>
                </div> -->
              <!-- </div> -->
              <div class="second" v-for="(item2, index2) in item.catalogueChilds" :key="index2">
                <div class="second-item">
                  第{{convertToChinaNum(index+1)}}节&nbsp;{{ item2.content }}
                </div>
                <div>
                  <!-- 这里原先是:href="detail.status?item2.url:'javascript:;'"，使用这个在这里也行，可以把点击函数去除，但是似乎无法控制视频大小之类的 -->
                  <a href="javascript:;" @click="goVideo(item2.url,item2.content)" class="notHave"  :class="{have:detail.status}">学习课程</a>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <div v-if="!detail.status" style="height: 90px;width:100% background-color: #fff"></div>
    <!-- <div class="bottom adapt" >
      <van-button
        v-if="!detail.status"
        type="primary"
        size="small"
        round
        color="linear-gradient(to right, #FF7200, #FF3C00)"
        @click="btnClick"
        >立即报名</van-button
      >
      <van-button v-else type="primary" size="small" round disabled color="#000"
        >已报名</van-button
      >
    </div> -->
  <van-row  v-if="!detail.status" class="bottom-btn">
            <!-- 底部按钮 -->
                <van-col :span="12">
                    <van-button @click="phoneShow=true" color="#313131" block style="border-radius:10px 0 0 10px;height:36px " >
                        <div class="align">
                            <img src="../../assets/BeiDa/电话.png"><span style="margin-left:10px">联系客服</span>
                        </div>
                    </van-button>
                </van-col>
                <van-col :span="12">
                    <van-button @click="show=true;" color="linear-gradient(to right,#FF7200, #FF3C00)" block style="border-radius:0 10px 10px 0;height:36px">
                        <div class="align">
                            <img src="../../assets/BeiDa/订单.png"><span style="margin-left:10px">立即报名</span> 
                        </div>
                    </van-button>
                </van-col>
        </van-row>
   
<!-- 报名 -->
    <van-popup
      v-model="show"
      position="bottom"
      round
      @close="$refs.vanForm.resetValidation()"
    >
      <div class="adapt">
        <div class="popup-title">课程报名</div>
        <van-form ref="vanForm" @submit="submit">
          <div class="form-item">
            <div class="item-label">报名课程</div>
            <div class="item-text">{{ detail.name }}</div>
          </div>

          <div class="form-item">
            <div class="item-label">姓名</div>
            <van-field
              v-model="personName"
              name="姓名"
              placeholder="请输入姓名"
              autocomplete="off"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
          </div>

          <div class="form-item">
            <div class="item-label">联系电话</div>
            <van-field
              v-model="phone"
              name="联系电话"
              placeholder="请输入联系电话"
              autocomplete="off"
              :rules="[
                { required: true, message: '请填写电话' },
                { pattern, message: '请填写正确的电话号码' },
              ]"
            />
          </div>
        </van-form>
        <div style="height: 56px"></div>
        <div class="bottom adapt">
          <van-button
            type="primary"
            size="small"
            round
            color="linear-gradient(to right, #FF7200, #FF3C00)"
            @click="btnClick"
            >立即报名</van-button
          >
        </div>
      </div>
    </van-popup>
<!-- 联系客服 -->
 <van-action-sheet v-model="phoneShow"  class="pop-phone">
    <div style="padding:10px">
      <van-button  block @click="callPhone" class="pop-btn"><div style="display:flex"><van-icon size="24" name="phone"/><div style="padding-left:10px; color: #1971D4;"> 呼叫 {{detail.servicePhone}}</div></div></van-button>
      <van-button block @click="phoneShow=false" class="pop-btn">取消</van-button>
    </div>
  </van-action-sheet>



    <share-box v-model="shareBoxShow" :data="shareData"></share-box>
  </div>
</template>

<script>
import {getBeiDaStudyClassDetail,getBeiDaClassApply} from '@/api/beiDaStudyClassDetail'
import shareBox from "@/components/share-box";
import { Toast } from 'vant';
export default {
  name: "beiDaClassDetail",
  components: { shareBox },
  data() {
    return {
      // id: "1481553829306699777",
      id: "1481794639205920769",
      share: null,
      detail: {
        img: null,
        name: null,
        price: null,
        peopleNum: null,
        introduction: null,
        pekingTeachersList: [],
        pekingCatalogueList: [],
        status: null,
        servicePhone:null,
      },

      active: 0,
      show: false,
      phoneShow:false,
      // isHave:0,
      personName: "",
      phone: "",
      pattern: /^1[0-9]{10}$/,

      shareBoxShow: false,
      shareData: {},
      shareUserId: null,
    };
  },
  created() {
    if (navigator.userAgent.indexOf("万民") == -1) {
      this.openApp();
      return;
    }
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
    this.id = this.$route.query.id;
    if (this.$route.query.shareUserId) {
      // this.share = this.$route.query.share;
      this.shareUserId = this.$route.query.shareUserId;
    }
    this.getStudyClassDetail();
  },
  methods: {
    // 获取详情
    getStudyClassDetail() {
      getBeiDaStudyClassDetail(this.id).then((res) => {
        console.log(res);
        this.detail = res.data.data;
        let url = window.location.href;
        this.shareData = {
          title: "北大学习",
          picUrl: this.detail.img,
          content: this.detail.name,
          openUrl:
            url.split("session")[0] +
            "shareUserId=" +
            this.detail.userId +
            "&session" +
            url.split("session")[1],
        };
      });
    },
    //播放视频
    goVideo(url,content){
      if (this.detail.status) {
        this.$router.push({name:"classVideo",query:{url,content}})
        console.log(this.$route)
      }
    },
    //拨号
    callPhone () {
    window.location.href = 'tel://' + this.detail.servicePhone;
    },
    // 立即报名按钮
    btnClick() {
      if( !navigator.userAgent.includes('万民')){
            let urls = location.href.split("#/")[1].split("&share=1");
            let url = urls[0] + urls[1];
            this.openApp(url);
            return
      }
        if( !navigator.userAgent.includes('万民')){ 
              let urls = location.href.split("#/")[1].split("&share=1");
              let url = urls[0] + urls[1];
              this.openApp(url);
              return
        } 
        if (this.show) {
          this.$refs.vanForm.submit();
        } else {
          this.show = true;
        }
    },
    // 表单提交
    submit() {
      let obj = {
        studyId: this.id,
        phone: this.phone,
        personName: this.personName,
        // paymentPrice: this.detail.price,
      };
      // console.log(obj);
      if (this.shareUserId) {
        obj.shareUserId = this.shareUserId;
      }
      getBeiDaClassApply(obj).then((res) => {
        if (res.data.code==0) {
          res.data.msg?Toast(res.data.msg):Toast("报名成功！")
        }else{
          Toast(res.data.msg);
        }
        console.log(res,"成功");
        // this.getLesson();
        this.getStudyClassDetail();
        this.show = false;
      });
    },
//阿拉伯数字转成中文数字
    convertToChinaNum(num) {
    var arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
    var arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千','万', '十', '百', '千','亿');//可继续追加更高位转换值
    if(!num || isNaN(num)){
        return "零";
    }
    var english = num.toString().split("")
    var result = "";
    for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i;//倒序排列设值
        result = arr2[i] + result;
        var arr1_index = english[des_i];
        result = arr1[arr1_index] + result;
    }
    //将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
    //合并中间多个零为一个零
    result = result.replace(/零+/g, '零');
    //将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
    //将【亿万】换成【亿】
    result = result.replace(/亿万/g, '亿');
    //移除末尾的零
    result = result.replace(/零+$/, '')
    //将【零一十】换成【零十】
    //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
    //将【一十】换成【十】
    result = result.replace(/^一十/g, '十');
    return result;
}
  },
};
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;
  margin-bottom: 10px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  .title {
    color: #333333;
    margin-bottom: 5px;
  }
  .price {
    color: #ff5200;
  }
  .num {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
}
/deep/ .van-tab__text {
  font-size: 16px;
}
.desc,
.teacher,
.catalog {
  background-color: #fff;
}
.desc {
  padding: 10px;
  /deep/ img{
    width: 100%;
  }
}
.teacher {
  .teacher-item {
    display: flex;
    padding: 16px;
    .item-left {
      flex-shrink: 0;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
    .item-right {
      margin-left: 14px;
      .name {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      .explain {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
.catalog {
  padding: 10px 16px;
  .catalog-item {
    margin-top: 5px;
    // >>>>>>>>>>>>>>
   
    
    .first {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      // margin-bottom: 5px;
    }
    .second {
      // margin-left: 12px;
      display:flex;
     justify-content:space-between;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      .second-item {
        margin-top: 8px;
        &:first-of-type {
          margin-top: 4px;
        }
      }
    }
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 56px;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  .van-button {
    height: 36px;
    margin-top: 10px;
    margin-left: 10px;
    width: calc(100% - 20px);
  }
}
.adapt {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.popup-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  height: 40px;
}
.form-item {
  border-bottom: 1px solid #eee;
  .item-label {
    padding: 10px 16px 0;
    font-size: 14px;
    color: #999;
  }
  .item-text {
    color: #333;
    padding: 10px 16px;
    font-size: 14px;
  }
}
/deep/.van-field__error-message {
  display: none;
}
    .bottom-btn{
    width: 100%;
    padding: 10px 16px 44px 16px;
    box-sizing: border-box;
    background: white;
    display:flex;
    position: fixed;
    bottom: 0px;
    // border-top: 1px solid #000;
    border-radius: 12px -12px 15px rgba(0,0,0,1);
}
.align{
    display: flex;
    // justify-content: center;
    align-items: center;
}
    .notHave{
        font-size:12px;
        color:#999999;
        text-decoration: underline;
    }
    .have{
        color: #FF5200;
    }
    .pop-phone{
      background: transparent;
      // padding: 10px;
    }
    .pop-btn{
      color: #6A6A6A;
      font-size: 20px;
      margin: 15px 0;
      border-radius:10px;
      background-color: #fff;
    }
</style>