import https from '@/router/https'
//北大学习课程详情
export function getBeiDaStudyClassDetail(id){
    return https.fetchGet(`/createrapp/pekingstudy/${id}`);
}
    //北大学习报名
// export function getBeiDaClassApply(data){
//     return https.fetchPut("/createrapp/pekingorder",data);
// }
export function getBeiDaClassApply(params){
    return https.fetchPost("/createrapp/pekingorder",params);
}
